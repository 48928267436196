import React from 'react';

function Index() {
  return (
    <div className="h-screen">
      <div className="h-full flex justify-center items-center bg-pink flex-col">
        <h4>Welcome to Gloo Assessment Results</h4>
        <p>As expected, there&apos;s nothing to show here.</p>
      </div>
    </div>
  );
}

export default Index;
